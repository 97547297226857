<template>
  <main id="VideoList">
    <div class="main_container">
      <VideoPage v-if="video_data.length > 0" :video_data="video_data" />
      <div class="row">
        <header>
          <p class="sub_title"><b>VIDEO GALLERY</b></p>
          <h3 class="title title_mark"><b>影音專區</b></h3>
        </header>

        <div class="card_list">
          <router-link
            :to="`/video/page/?id=${item.video_id}`"
            @click.native="GA_ViewVideo(item.title)"
            class="card link_mark"
            v-for="(item, item_index) in video_data"
            :key="item.id"
          >
            <div class="img_box">
              <img v-if="item.cover != ''" :src="item.cover" />
              <img v-else src="/img/index.jpg" />
            </div>
            <div class="content">
              <div class="header">
                <time>{{ item.create_time.slice(0, 10) }}</time>
              </div>
              <h3>{{ item.title }}</h3>
              <span>{{ (item_index + 1) | number }}</span>
            </div>
          </router-link>
        </div>
        <div class="list_footer">
          <button
            v-if="active_page * 9 < original_data.length"
            @click="LoadMore"
            class="more_btn link_mark"
          >
            <div><b>讀取更多</b></div>
          </button>
        </div>
      </div>
      <MainFooter />
    </div>
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import MainFooter from '@/components/MainFooter';
import VideoListGSAP from '@/common/GSAP/Video/video_list.js';
import VideoPage from '@/components/Video/VideoPage';
import { getVideoList } from '@/api/video.js';
import { GetMetaData } from '@/common/meta';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'VideoList',
  mixins: [SmoothScrollResister, VideoListGSAP],
  components: {
    MainFooter,
    VideoPage,
  },
  data() {
    return {
      category: [
        {
          text: '全部',
          value: 'all',
        },
        {
          text: '重要消息',
          value: 'important',
        },
        {
          text: '優惠消息',
          value: 'sale',
        },
        {
          text: '活動消息',
          value: 'event',
        },
      ],
      video_data: [],
      original_data: [],
      active_page: 1,
      news_type: 'all',
      meta_data: null,
    };
  },
  methods: {
    LoadMore() {
      if ((this.active_page + 1) * 9 <= this.original_data.length) {
        this.active_page += 1;
        this.video_data = this.original_data.splice(0, this.active_page * 9);
        this.$nextTick(() => {
          this.InitCard((this.active_page - 1) * 9 - 1);
          this.$emit('init-cursor');
        });
      }
    },
    async GetVideoData() {
      getVideoList().then((res) => {
        let data = res.data;
        this.original_data = data;
        this.original_data.sort((a, b) => {
          return a.position - b.position;
        });
        this.video_data = JSON.parse(JSON.stringify(this.original_data));
        this.video_data = this.video_data.splice(0, this.active_page * 9);
        if (
          this.$route.query.id &&
          this.original_data.filter(
            (item) => item.video_id == this.$route.query.id
          ).length <= 0
        ) {
          ReturnErrorPage();
        } else {
          if (this.$route.query.id) {
            let video = this.original_data.filter(
              (item) => item.video_id == this.$route.query.id
            )[0];
            this.meta_data = GetMetaData(
              video.title,
              '歡迎使用MJP 茂建科技官方網站，產品介紹影片、安裝教學、賽事活動影片。',
              video.cover
            );
          } else {
            this.meta_data = GetMetaData(
              '影音專區',
              '歡迎使用MJP 茂建科技官方網站，產品介紹影片、安裝教學、賽事活動影片。',
              this.metainfo_image
            );
          }

          this.$nextTick(() => {
            this.InitCard((this.active_page - 1) * 9 - 1);
            let video_page = document.getElementById('VideoPage');
            this.RegisterGsap('VideoList', [video_page]);
            this.$emit('init-cursor');
            const el = document.querySelector('#VideoPage');
            ['touchmove', 'mousewheel', 'wheel'].forEach((eventType) => {
              el.addEventListener(eventType, (e) => e.stopPropagation());
            });

            window.prerenderReady = true;
          });
        }
      });
    },
  },
  filters: {
    number(val) {
      return val < 10 ? '0' + val : val;
    },
  },
  watch: {
    $route() {
      if (this.$route.params.type == 'page') {
        this.ModalToggle(true);
      } else {
        this.ModalToggle(false);
      }
    },
  },
  created() {
    this.GetVideoData();
  },
  mounted() {},
  metaInfo() {
    return this.meta_data;
  },
};
</script>
