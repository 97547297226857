<template>
  <article v-show="$route.params.type == 'page'" id="VideoPage">
    <transition name="video_article">
      <div
        v-if="$route.params.type == 'page' && active_video != null"
        class="container"
      >
        <div class="img_box">
          <button class="close_btn" @click="$router.push('/video/list/')">
            <span class="material-icons-outlined"> close </span>
          </button>
          <div v-html="video"></div>
        </div>
        <div class="content_box" v-if="video_data.length > 0">
          <header>
            <h2>{{ active_video.title }}</h2>
            <time>{{ active_video.create_time.slice(0, 10) }}</time>
          </header>
          <div class="content" v-html="active_video.description"></div>
          <div class="article_footer">
            <p>分享此文章</p>
            <div class="button_group">
              <button @click="ShareToFB" class="facebook_share">
                Facebook
              </button>
              <button @click="ShareToLine" class="line_share">LINE</button>
              <button @click="CopyLink" class="cpoy_link">複製連結</button>
            </div>

            <router-link to="/video/list/1" class="back_btn link_mark"
              ><span class="material-icons"> arrow_left </span
              >回到影音相簿列表</router-link
            >
          </div>
        </div>
      </div>
    </transition>
  </article>
</template>

<script>
export default {
  name: "VideoPage",
  props: {
    video_data: {
      require: true
    }
  },
  data() {
    return {
      player: null,
      video: ""
    }
  },
  methods: {
    InitVideo() {
      this.video = "";
      if (this.video_data.length > 0) {
        let id = this.video_data.filter(item => item.video_id == this.$route.query.id)[0].video.split("?v=")[1].split('&')[0]
        this.video = `<iframe
            id="player"
            type="text/html"
            src="https://www.youtube.com/embed/${id}"
            frameborder="0"
          ></iframe>`;
      }
    },

    ShareToFB() {
      window.open(`https://www.facebook.com/sharer.php?u=${window.location.href}`, '_blank').focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
    CopyLink() {
      /* Copy the text inside the text field */
      // navigator.clipboard.writeText(window.location.href);
      var copyText = document.getElementById("UrlInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      /* Alert the copied text */
      alert("已複製連結至剪貼簿");
    }
  },
  created() {
    if (this.$route.params.type == "page") {
      this.InitVideo()
    }
  },
  watch: {
    video_data() {
      if (this.video_data != null) {
        this.InitVideo()
      }
    },
    "$route"() {
      if (this.$route.params.type == "page") {
        this.InitVideo()
      }
    }
  },
  computed: {
    active_video() {
      let video = this.video_data.filter(item => item.video_id == this.$route.query.id)
      return video.length > 0 ? video[0] : null
    }
  },
}
</script>